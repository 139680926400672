import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "160",
  height: "167",
  fill: "none",
  viewBox: "0 0 160 167"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"80\" cy=\"80\" r=\"79.143\" fill=\"#F6F8FF\" stroke=\"#ECF2FF\" stroke-width=\"1.714\"></circle><mask id=\"a\" fill=\"#fff\"><path fill-rule=\"evenodd\" d=\"M110.991 30.612H35.918c-6.311 0-11.428 5.117-11.428 11.429v113.103c0 6.311 5.117 11.428 11.428 11.428h88.164c6.311 0 11.428-5.117 11.428-11.428V55.131z\" clip-rule=\"evenodd\"></path></mask><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M110.991 30.612H35.918c-6.311 0-11.428 5.117-11.428 11.429v113.103c0 6.311 5.117 11.428 11.428 11.428h88.164c6.311 0 11.428-5.117 11.428-11.428V55.131z\" clip-rule=\"evenodd\"></path><path fill=\"#ECF2FF\" d=\"m110.991 30.612 1.213-1.212-.503-.502h-.71zm24.519 24.52h1.714v-.711l-.502-.502zM35.918 32.326h75.073v-3.429H35.918zm-9.714 9.714c0-5.365 4.35-9.714 9.714-9.714v-3.429c-7.258 0-13.142 5.884-13.142 13.143zm0 113.103V42.041h-3.428v113.103zm9.714 9.714c-5.365 0-9.714-4.349-9.714-9.714h-3.428c0 7.258 5.884 13.142 13.142 13.142zm88.164 0H35.918v3.428h88.164zm9.714-9.714c0 5.365-4.349 9.714-9.714 9.714v3.428c7.258 0 13.142-5.884 13.142-13.142zm0-100.013v100.013h3.428V55.131zm2.926-1.212L112.204 29.4l-2.425 2.425 24.519 24.518z\" mask=\"url(#a)\"></path><path fill=\"#ECF2FF\" stroke=\"#ECF2FF\" stroke-linecap=\"round\" stroke-width=\"1.714\" d=\"M133.441 54.653h-21.972V32.682z\"></path><path stroke=\"#ECF2FF\" stroke-linecap=\"round\" stroke-width=\"3.429\" d=\"M45.796 96.653h69.224M45.796 81.551h69.224M45.796 111.754h69.224M45.796 126.857h36.163\"></path>", 6)
  ])))
}
export default { render: render }